<template>
  <div class="trading-ui-event-search">
    <div class="event-search-header">
      <TextInput
        class="search-field"
        :model-value="eventSearch"
        @update:modelValue="updateSearch"
        icon="search"
        placeholder="Search event"
        small
      />
    </div>
    <div class="event-search-body">
      <Spinner
        v-if="eventsLoading"
        small
      />
      <p v-if="!eventsLoading && !events.length">
        No events found
      </p>
      <div
        v-if="!eventsLoading && events.length"
        class="event-items"
      >
        <div
          v-for="event in events"
          :key="event.eventId"
          class="event-item"
          @click="onSelectEvent(event.eventId)"
        >
          <div>{{ formatEventName(event) }}</div>
          <div class="event-additional-info">
            <div class="additional-info-item">
              <Icon
                name="calendar"
              />
              {{ formatDate(event.startsAt) }}
            </div>
            <div class="additional-info-item">
              <Icon
                name="clock"
              />
              {{ formatTime(event.startsAt) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import {
  split,
  debounce,
  reverse,
  join,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import Icon from '@/components/common/Icon';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    Icon,
    TextInput,
    Spinner,
  },
  emits: ['eventSelected'],
  setup(_, { emit }) {
    const store = useStore();
    const eventSearch = ref('');
    const events = computed(() => store.getters.tradingEventList);
    const eventsLoading = computed(() => store.getters.tradingEventListLoading);

    const updateSearch = debounce((val) => {
      eventSearch.value = val;
      store.dispatch('loadSearchEventListForTrading', val);
    }, 500);

    const onSelectEvent = (eventId) => {
      emit('eventSelected', eventId);
    };

    const formatEventName = ({ eventName, isUsaView }) => {
      const competitors = split(eventName, ' v ');
      if (isUsaView) return join(reverse(competitors), ' @ ');
      return join(competitors, ' vs ');
    };
    const formatDate = (eventStartsAt) => format(zonedTimeToUtc(eventStartsAt, 'UTC'), 'MMM d, yyyy');
    const formatTime = (eventStartsAt) => format(zonedTimeToUtc(eventStartsAt, 'UTC'), 'hh:mm a');

    onMounted(() => store.dispatch('loadSearchEventListForTrading', ''));

    return {
      eventSearch,
      events,
      eventsLoading,
      updateSearch,
      onSelectEvent,
      formatEventName,
      formatDate,
      formatTime,
    };
  },
};
</script>

<style lang="scss">
.trading-ui-event-search {
  height: 300px;
  width: 370px;
  background-color: $white;
  border: 1px solid $gray500;
  box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);
  position: fixed;
  overflow: hidden;
  border-radius: 4px;
  z-index: 9000;

  .event-search-header {
    height: 53px;
    padding: 8px;
    border-bottom: 1px solid $gray500;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .event-search-body {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: calc(100% - 53px);

    .event-items {
      width: 100%;
      padding: 8px;
      overflow: auto;
      height: 100%;

      .event-item {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        height: 55px;
        padding: 8px 4px;
        cursor: pointer;
        justify-content: center;

        &:hover {
            background-color: $gray300;
        }

        .event-additional-info {
          font-size: 12px;
          display: flex;
          gap: 8px;
          color: #807f7f;

          .additional-info-item {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #A9A9A9;
          }
        }
      }
    }
  }
}
</style>
