<template>
  <div class="trading-market-selection">
    <div
      class="trading-market-selection-name"
      :title="!remapSelectionCode.twoPartLabel
        ? remapSelectionCode
        : `${remapSelectionCode.leftSide}/${remapSelectionCode.rightSide}`"
    >
      <span v-if="!remapSelectionCode.twoPartLabel">{{ remapSelectionCode }}</span>
      <div
        v-else
        class="two-part-selection-code"
      >
        <span class="left-side">{{ remapSelectionCode.leftSide }}</span>
        <span class="divider">/</span>
        <span class="right-side">{{ remapSelectionCode.rightSide }}</span>
      </div>
    </div>
    <div class="trading-market-selection-values-group">
      <div class="trading-market-selection-column line">
        {{ displayLine }}
      </div>
      <div class="trading-market-selection-column price">
        {{ odd }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  includes, toLower, replace, split,
} from 'lodash';
import OddsConverter from '@/services/helpers/odds-converter';
import MarketMapper from '@/services/helpers/market-mapper';

export default {
  components: {
  },
  props: {
    market: {
      type: Object,
      required: true,
    },
    selection: {
      type: Object,
      required: true,
    },
    sportLabel: {
      type: String,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
    isSimulatedPreviewOn: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const displayLine = computed(() => {
      const line = props.market.marketType.params.LINE;
      if (!line) return '';
      if (!includes(props.market.marketType.marketCode, 'HANDICAP')) return line;
      let lineValue = '';
      lineValue = line;
      if (props.selection.selectionType.selectionCode === 'HOME' && line > 0) {
        lineValue = `+${line}`;
      }
      if (props.selection.selectionType.selectionCode === 'AWAY' && line < 0) {
        lineValue = `+${Math.abs(line)}`;
      }
      if (props.selection.selectionType.selectionCode === 'AWAY' && line > 0) {
        lineValue = `-${line}`;
      }
      return lineValue;
    });

    const selectedOddFormat = computed(() => store.getters.selectedOddFormat?.name);

    const odd = computed(() => {
      const americanOdd = props.selection?.price?.originalFormattedValue;
      if (props.isSimulatedPreviewOn && !americanOdd) return parseFloat(props.selection.price.probability.toFixed(2));
      if (americanOdd && selectedOddFormat.value === 'american') return `${americanOdd.signIsPlus ? '+' : '-'}${americanOdd.value}`;
      if (selectedOddFormat.value === 'probability') {
        const probability = parseFloat((props.selection.price.probability + props.selection.price.bias) * 100).toFixed(1);
        return (probability % 1 > 0) ? `${probability}%` : `${Math.round(probability)}%`;
      }
      const price = props.selection?.price;
      return OddsConverter.getMappedOddValue(price, selectedOddFormat.value);
    });

    const setTeamNames = (selectionLabel) => {
      let replacedLabel = toLower(selectionLabel);
      if (includes(replacedLabel, 'home') && replacedLabel !== 'home run') {
        replacedLabel = replace(replacedLabel, /home/g, props.homeTeam?.name);
      }
      if (includes(replacedLabel, 'away')) {
        replacedLabel = replace(replacedLabel, /away/g, props.awayTeam?.name);
      }
      if (includes(replacedLabel, '/')) {
        const labelSplited = split(replacedLabel, '/');
        replacedLabel = {
          twoPartLabel: true,
          leftSide: labelSplited[0],
          rightSide: labelSplited[1],
        };
      }
      return replacedLabel;
    };

    const remapSelectionCode = computed(() => {
      const selectionNameLabel = MarketMapper.selectionName(props.selection, { sportLabel: props.sportLabel }, props.isUsaView);
      return setTeamNames(selectionNameLabel);
    });

    return {
      displayLine,
      odd,
      remapSelectionCode,
    };
  },
};
</script>

<style lang="scss">
.trading-market-selection {
  height: 33px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  .trading-market-selection-name {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;

    span {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
  }

  .trading-market-selection-values-group {
    height: 100%;
    width: 50%;
    display: flex;

    .trading-market-selection-column {
      width: 50%;
      margin-right: 8px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;

      &.line {
        font-weight: 600;
      }

      &.price {
        font-weight: 400;
      }

      &.input {
        height: 33px;
        width: 56px;
        border-radius: 4px;
        margin-right: 0;

        .text-field-input {
          border-color: #DDD;
          text-align: center;
        }
      }
    }
  }
}
</style>
