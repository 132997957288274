<template>
  <div
    class="market-section-wrapper"
    ref="marketRef"
  >
    <div
      class="trading-market-hidden-placeholder"
      v-show="!marketVisible"
    >
      {{ market.displayName }}
    </div>
    <div
      class="market-card"
      v-if="marketVisible"
    >
      <MarketCard
        :market="market"
        :has-borders="false"
        :has-toggle="true"
        :has-checkbox="true"
        :multi-view="false"
        :selected-trading-markets="selectedTradingMarkets"
        :event-id="eventId"
        :is-usa-view="isUsaView"
        :sport-id="sportId"
        :sport-label="sportLabel"
        :competition-id="competitionId"
        :home-team="homeTeam"
        :away-team="awayTeam"
        :market-favorites="marketFavorites"
      />
    </div>
  </div>
</template>

<script>
import {
  ref, onMounted, onUnmounted, computed,
} from 'vue';
import { useStore } from 'vuex';
import { each } from 'lodash';
import MarketMapper from '@/services/helpers/market-mapper';
import MarketCard from './MarketCard';

export default {
  components: {
    MarketCard,
  },
  props: {
    marketId: {
      type: String,
      required: true,
    },
    selectedTradingMarkets: {
      type: Array,
      required: false,
      default: () => [],
    },
    eventId: {
      type: String,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
    sportId: {
      type: String,
      required: true,
    },
    sportLabel: {
      type: String,
      required: true,
    },
    competitionId: {
      type: String,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
    marketFavorites: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const observer = ref(null);
    const marketVisible = ref(false);
    const marketRef = ref(null);

    const market = computed(() => {
      const storeMarket = store.getters.getTradingMarketById(props.marketId);
      const marketDisplayConfiguration = store.getters.getSingleMarketDisplayConfiguration(storeMarket.marketCode, storeMarket.marketType.params.SELECTIONS);
      const mappedMarket = MarketMapper.mapUngroupedMarket({
        marketDisplayConfiguration,
        market: storeMarket,
        homeTeam: props.homeTeam,
        awayTeam: props.awayTeam,
      });
      return {
        ...mappedMarket,
        selections: MarketMapper.sortSelections({ selections: mappedMarket.selections, isUSAView: props.isUsaView }),
      };
    });

    onMounted(() => {
      const options = {
        root: null,
        threshold: 0.001,
      };
      observer.value = new IntersectionObserver((entries) => {
        each(entries, (entry) => {
          marketVisible.value = entry.isIntersecting;
        });
      }, options);
      observer.value.observe(marketRef.value);
    });

    onUnmounted(() => {
      observer.value = null;
    });

    return {
      marketVisible,
      marketRef,
      market,
    };
  },
};
</script>

<style lang="scss">
.market-section-wrapper {
  .trading-market-hidden-placeholder {
    height: 66px;
    width: 100%;
    border-bottom: 1px solid #DDD;
    padding: 8px 8px 8px 69px;
    font-weight: 600;
  }
}
</style>
