<template>
  <div class="event-view">
    <div class="event-view__box">
      <Scoreboard
        :event="event"
      />
      <PlayByPlay
        v-if="showPlayByPlay"
        :event="event"
      />
      <div
        class="event-view__separator"
      />
      <Statistics
        :event="event"
      />
    </div>
    <div
      v-if="isFootball && live"
      class="event-view__box event-view__box--drive-outcome-probabilities event-view__box--scrollable"
    >
      <DriveOutcomeProbabilities />
    </div>
    <div class="event-view__box event-view__box--is-players event-view__box--scrollable">
      <Players
        :event="event"
        :player-props="playerProps || {}"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { isPlayByPlayDisplayable } from '@/services/helpers/play-by-play';
import PlayByPlay from '@/components/common/PlayByPlay';
import Scoreboard from '@/components/trading-ui/common/Scoreboard';
import Statistics from '@/components/trading-ui/common/Statistics';
import Players from '@/components/trading-ui/common/Players';
import Event from '@/services/helpers/event';
import DriveOutcomeProbabilities from '@/components/trading-ui/common/DriveOutcomeProbabilities';
import sportIds from '@/services/helpers/sports';

const {
  FOOTBALL_ID,
} = sportIds;

export default {
  components: {
    PlayByPlay,
    Scoreboard,
    Statistics,
    Players,
    DriveOutcomeProbabilities,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const showPlayByPlay = computed(() => isPlayByPlayDisplayable(props.event));
    const playerProps = computed(() => store.getters.tradingPlayerProps);
    const isFootball = computed(() => props.event.sport?.id === FOOTBALL_ID);
    const live = computed(() => Event.isLive(props.event) && props.event.period !== 'EVENT_COMPLETED');
    return {
      showPlayByPlay,
      playerProps,
      isFootball,
      live,
    };
  },
};
</script>

<style lang="scss" scoped>
.event-view {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
}

.event-view__box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #FFF;
  border: 1px solid #DDD;
  border-radius: 4px;
  padding: 16px;

  &--is-players {
    min-height: 200px;
  }

  &--drive-outcome-probabilities {
    min-height: 155px;
  }

  &.event-view__box--scrollable {
    overflow: auto;
  }
}

.event-view__separator {
  background-color: #F0F0F0;
  height: 1px;
}
</style>
