<template>
  <div class="trading-main-markets-wrapper">
    <MarketCard
      v-for="market in mappedMainMarkets"
      :key="market.marketId"
      :market="market"
      :event-id="eventId"
      :has-borders="true"
      :has-toggle="false"
      :multi-view="false"
      :is-usa-view="isUsaView"
      :sport-id="sportId"
      :sport-label="sportLabel"
      :competition-id="competitionId"
      :home-team="homeTeam"
      :away-team="awayTeam"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { map, find, includes } from 'lodash';
import MarketMapper from '@/services/helpers/market-mapper';
import MarketCard from './MarketCard';

export default {
  components: {
    MarketCard,
  },
  props: {
    markets: {
      type: Array,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
    sportId: {
      type: String,
      required: true,
    },
    sportLabel: {
      type: String,
      required: true,
    },
    competitionId: {
      type: String,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const mainMarkets = [
      {
        label: 'Moneyline',
        partialMarketCode: 'RESULT',
        hasLine: false,
      },
      {
        label: 'Spread',
        partialMarketCode: 'HANDICAP',
        hasLine: true,
      },
      {
        label: 'Total',
        partialMarketCode: 'OVER_UNDER',
        hasLine: true,
      },
    ];

    const mappedMainMarkets = computed(() => map(mainMarkets, (market) => {
      const foundMainMarket = find(props.markets, (propMarket) => includes(propMarket.marketCode, market.partialMarketCode));
      if (!foundMainMarket) return market;
      return {
        ...market,
        ...foundMainMarket,
        selections: MarketMapper.sortSelections({ ...foundMainMarket, isUSAView: props.isUsaView }),
      };
    }));
    return {
      mappedMainMarkets,
    };
  },
};
</script>

<style lang="scss">
.trading-main-markets-wrapper {
  width: 100%;
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
</style>
