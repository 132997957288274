<template>
  <div
    :class="[
      'trading-market',
      {
        'has-borders': hasBorders,
        'suspended': market?.offeringStatus === 'SUSPENDED' && !isSimulatedPreviewOn,
        'resulted': isResulted,
        'multi-view': multiView,
      }]"
  >
    <div
      class="trading-market-header"
      @contextmenu.prevent="openActionsDropdown"
    >
      <div
        class="trading-market-header-icons left"
        @click.stop="toggleMarket"
        v-if="hasCheckbox || hasToggle"
      >
        <Icon
          :name="marketToggled ? 'chevron-up' : 'chevron-down'"
          @click.stop="toggleMarket"
          v-if="hasToggle"
        />
        <Checkbox
          v-if="hasCheckbox"
          :disabled="isResulted || marketHasNoPrice || autoSuspended"
          :model-value="marketSelected"
          @update:modelValue="selectMarket"
        />
        <Icon
          :name="'star'"
          class="star-icon"
          v-if="hasToggle && !isFavorite"
          @click.stop="updateMarketFavorites"
        />
        <Icon
          :name="'star-filled'"
          class="star-icon"
          v-if="hasToggle && isFavorite"
          @click.stop="updateMarketFavorites"
        />
      </div>
      <div
        class="trading-market-header-label"
        :title="market?.label ? market.label : market?.displayName"
        @click.stop="toggleMarket"
      >
        <div
          class="market-name"
          v-if="!multiView"
        >
          {{ market?.label ? market.label : market?.displayName }}
        </div>
        <div class="market-statuses">
          <div
            v-if="!isResulted && !autoSuspended && market?.marketCode"
            :class="{'active': market?.offeringStatus !== 'SUSPENDED', 'inactive': market?.offeringStatus === 'SUSPENDED'}"
          >
            {{ market?.offeringStatus === 'SUSPENDED' ? 'Suspended' : 'Active' }}
          </div>
          <div
            v-if="!isResulted && !autoSuspended && market?.marketCode"
            class="dot-separator"
          >
            •
          </div>
          <div
            v-if="!isResulted && !autoSuspended && market?.marketCode"
            :class="{'active': market?.displayStatus === 'ON', 'inactive': market?.displayStatus === 'OFF'}"
          >
            {{ market?.displayStatus === 'OFF' ? 'Hidden' : 'Visible' }}
          </div>
          <div
            v-if="autoSuspended && !isResulted && !isSimulatedPreviewOn"
            class="inactive"
          >
            Auto suspended
          </div>
          <div
            class="resulted"
            v-if="isResulted"
          >
            Resulted
          </div>
          <div
            class="resulted"
            v-if="isSimulatedPreviewOn"
          >
            Simulated
          </div>
          <div
            class="resulted"
            v-if="!market?.marketCode"
          >
            Not available
          </div>
        </div>
      </div>
      <div
        v-if="!isResulted && !autoSuspended && market?.marketCode && !isSimulatedPreviewOn"
        :class="['trading-market-header-buttons-group', {'disabled': marketHasNoPrice || marketSuspendLoading}]"
        ref="marketActionsDropdownRef"
      >
        <div
          class="trading-market-header-button suspend"
          @click="changeMarketSuspension"
        >
          <Icon
            :name="market?.offeringStatus === 'SUSPENDED' ? 'play' : 'pause'"
            :class="{'pause': market?.offeringStatus !== 'SUSPENDED'}"
          />
        </div>
        <div
          class="trading-market-header-button"
          @click="openActionsDropdown"
        >
          <Icon :name="marketActionsDropdownDisplayed ? 'chevron-up' : 'chevron-down'" />
        </div>
        <div
          class="market-actions-dropdown"
          v-if="marketActionsDropdownDisplayed"
          :style="dropdownStyle"
        >
          <div
            class="market-actions-dropdown-item"
            @click.stop="changeMarketSuspensionAllLines('unsuspend')"
          >
            <Icon
              class="activate-lines"
              :name="'play'"
            />
            <div>Activate all lines</div>
          </div>
          <div
            class="market-actions-dropdown-item"
            @click.stop="changeMarketSuspensionAllLines('suspend')"
          >
            <Icon
              class="suspend-lines"
              :name="'pause'"
            />
            <div class="suspend-lines-label">
              Suspend all lines
            </div>
          </div>
          <div
            class="market-actions-dropdown-item"
            v-if="market?.displayStatus === 'OFF'"
            @click="changeMarketDisplay"
          >
            <Icon
              :name="'eye'"
            />
            <div>Show market</div>
          </div>
          <div
            class="market-actions-dropdown-item"
            v-if="market?.displayStatus === 'ON'"
            @click="changeMarketDisplay"
          >
            <Icon
              :name="'eye-off'"
            />
            <div>Hide market</div>
          </div>
          <div
            class="market-actions-dropdown-item"
            @click="showAllLines"
          >
            <Icon
              :name="'eye'"
            />
            <div>Show all lines</div>
          </div>
          <div
            class="market-actions-dropdown-item"
            @click="hideAllLines"
          >
            <Icon
              :name="'eye-off'"
            />
            <div>Hide all lines</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="trading-market-risk-hold"
      v-if="marketToggled"
    >
      <div class="trading-market-risk-hold-group">
        <div class="trading-market-risk-hold-label">
          TIER:
        </div>
        <NumberInput
          class="market-tier-input"
          :model-value="localMarketTierValue"
          :readonly="!marketTierValue || isResulted || marketHasNoPrice || marketTierInputDisabled || autoSuspended || isSimulatedPreviewOn"
          :max="4"
          :has-arrows="true"
          @update:modelValue="onMarketTierChange"
          @onKeyUp="onMarketTierIncrease"
          @onKeyDown="onMarketTierDecrease"
        />
      </div>
      <div class="trading-market-risk-hold-group">
        <div class="trading-market-risk-hold-label">
          HOLD:
        </div>
        <div class="trading-market-risk-hold-value">
          {{ marketMargin }}%
        </div>
      </div>
    </div>
    <div
      class="trading-market-selections-wrapper"
      v-if="marketToggled"
    >
      <div
        class="trading-market-selections"
        :class="{ 'bias-slider-active': biasSliderActive }"
      >
        <MarketSelection
          v-for="selection in marketSelections"
          :key="selection.selectionId"
          :selection="selection"
          :market="market"
          :sport-label="sportLabel"
          :home-team="homeTeam"
          :away-team="awayTeam"
          :is-usa-view="isUsaView"
          :is-simulated-preview-on="isSimulatedPreviewOn"
        />
      </div>
      <div class="trading-market-slider">
        <Slider
          v-if="biasSliderActive"
          :class="{'is-positive-rotate': isUSASliderRotate}"
          :model-value="biasValueRef"
          :tooltips="false"
          :lazy="false"
          :min="-6"
          :max="6"
          :step="2"
          :disabled="isResulted || marketHasNoPrice || sliderDisabled || autoSuspended || isSimulatedPreviewOn || !sliderEnabled"
          @update:modelValue="onBiasSliderUpdate"
        />
        <div
          class="slider-value"
          :class="`bias${biasValueLabel}`"
        >
          {{ biasValueLabel }}
        </div>
      </div>
    </div>
    <div
      v-if="!market?.marketCode"
      class="market-not-found"
    >
      No data available.
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import {
  cloneDeep, each, find, includes, isNil, debounce,
} from 'lodash';
import NumberInput from '@/components/common/NumberInput';
import Icon from '@/components/common/Icon';
import Checkbox from '@/components/common/Checkbox';
import Slider from '@/components/common/Slider';
import MarketSelection from './MarketSelection';
import { MARKET_TIER_MIN_VALUE, MARKET_TIER_MAX_VALUE } from '@/services/helpers/trading-ui-mapper';
import * as api from '@/services/api';

export default {
  components: {
    Icon,
    MarketSelection,
    NumberInput,
    Checkbox,
    Slider,
  },
  props: {
    market: {
      type: Object,
      required: true,
    },
    hasBorders: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hasToggle: {
      type: Boolean,
      required: true,
    },
    hasCheckbox: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    selectedTradingMarkets: {
      type: Array,
      required: false,
      default: () => [],
    },
    eventId: {
      type: String,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
    sportId: {
      type: String,
      required: true,
    },
    sportLabel: {
      type: String,
      required: true,
    },
    competitionId: {
      type: String,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
    multiView: {
      type: Boolean,
      required: true,
    },
    isSimulatedPreviewOn: {
      type: Boolean,
      required: false,
    },
    marketFavorites: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const inputValue = ref(3);
    const marketToggled = ref(!cloneDeep(props.hasToggle));
    const sliderDisabled = ref(false);
    const marketTierInputDisabled = ref(false);
    const marketSuspendLoading = ref(false);

    const marketSelections = computed(() => props.market.selections);

    const marketActionsDropdownRef = ref(null);
    const marketActionsDropdownDisplayed = ref(false);

    const isResulted = computed(() => props.market.resultingStatus === 'RESULTED');
    const autoSuspended = computed(() => props.market.offeringStatus === 'SUSPENDED' && props.market.gateway !== 'MANUAL');
    const isHomeAwaySelection = computed(() => !!find(props.market.selections, (selection) => includes(['HOME'], selection?.selectionType?.selectionCode)));
    const biasValue = computed(() => {
      const mainSelection = find(props.market.selections, (selection) => includes(['HOME', 'YES', 'OVER'], selection?.selectionType?.selectionCode));
      const val = parseInt(mainSelection?.price?.bias * 100, 10);
      return val;
    });
    const isUSAView = computed(() => props.isUsaView);
    const biasValueRef = ref(biasValue.value || 0);
    // The case when we have USAView and HOME/AWAY selection in order to properly show the values we need to flip the slider
    const isUSASliderRotate = computed(() => isHomeAwaySelection.value && isUSAView.value);
    const biasValueLabel = computed(() => (isUSASliderRotate.value ? biasValueRef.value * -1 : biasValueRef.value));
    const biasSliderActive = computed(() => props.market.selections?.length === 2);

    const isSimpleTiered = computed(() => props.market.limitConfiguration?.type === 'SIMPLE_TIERED');
    const isMainLine = computed(() => props.market.marketSummary?.isMainLine);
    const marketTierValue = computed(() => {
      // limitConfiguration.type
      // SIMPLE TIERED -> markets without line
      // MAIN_ALT_TIERED -> markets with line
      if (isSimpleTiered.value) return props.market.limitConfiguration?.tier;
      return (isMainLine.value ? props.market.limitConfiguration?.mainTier : props.market.limitConfiguration?.altTier);
    });
    const localMarketTierValue = ref(marketTierValue.value);

    const marketHasNoPrice = computed(() => !find(props.market.selections, (selection) => selection.price?.originalFormattedValue?.value));

    const marketMargin = computed(() => {
      let marginSum = 0;
      each(props.market.selections, (selection) => {
        if (!isNil(selection.price.margin)) marginSum += selection.price.margin;
      });
      if (parseFloat(marginSum).toFixed(2) === '0.00' || parseFloat(marginSum).toFixed(2) === '-0.00') return 0; // quants have wrong math and we get numbers -0.232321331e-9 so it gets fixed to 0.00 and we format it
      return parseFloat(marginSum * 100).toFixed(2);
    });

    const toggleMarket = () => {
      if (!props.hasToggle) return;
      marketToggled.value = !marketToggled.value;
    };

    const changeMarketSuspension = () => {
      if (marketHasNoPrice.value || marketSuspendLoading.value) return;
      marketSuspendLoading.value = true;
      if (props.market.offeringStatus === 'SUSPENDED') {
        store.dispatch('updateMarketSuspensionByMarketId', {
          eventId: props.eventId,
          marketId: props.market.marketId,
          operation: 'unsuspend',
          allLines: false,
        })
          .then(() => {
            marketSuspendLoading.value = false;
          })
          .catch((error) => {
            marketSuspendLoading.value = false;
            console.log(error);
          });
        return;
      }
      store.dispatch('updateMarketSuspensionByMarketId', {
        eventId: props.eventId,
        marketId: props.market.marketId,
        operation: 'suspend',
        allLines: false,
      })
        .then(() => {
          marketSuspendLoading.value = false;
        })
        .catch((error) => {
          marketSuspendLoading.value = false;
          console.log(error);
        });
    };

    const changeMarketSuspensionAllLines = (operation) => {
      if (marketHasNoPrice.value || marketSuspendLoading.value) return;
      marketSuspendLoading.value = true;
      marketActionsDropdownDisplayed.value = false;
      store.dispatch('updateMarketSuspensionByMarketId', {
        eventId: props.eventId,
        marketId: props.market.marketId,
        operation,
        allLines: true,
      })
        .then(() => {
          marketSuspendLoading.value = false;
        })
        .catch((error) => {
          marketSuspendLoading.value = false;
          console.log(error);
        });
    };

    const changeMarketDisplay = () => {
      if (marketHasNoPrice.value) return;
      marketActionsDropdownDisplayed.value = false;
      if (props.market.displayStatus === 'OFF') {
        store.dispatch('displayMarket', { eventId: props.eventId, marketId: props.market.marketId });
        return;
      }
      store.dispatch('undisplayMarket', { eventId: props.eventId, marketId: props.market.marketId });
    };

    const updateMarketTier = debounce((newVal) => {
      if ((newVal >= MARKET_TIER_MIN_VALUE && newVal <= MARKET_TIER_MAX_VALUE) || (!newVal && newVal !== 0)) {
        localMarketTierValue.value = null;
        const timeout = setTimeout(() => {
          localMarketTierValue.value = newVal;
          clearTimeout(timeout);
        }, 0);
      } else {
        const oldValue = localMarketTierValue.value;
        localMarketTierValue.value = null;
        const timeout = setTimeout(() => {
          localMarketTierValue.value = oldValue;
          clearTimeout(timeout);
        }, 0);
        return;
      }
      if (!newVal) return;
      const payload = {
        limitConfiguration: {
          type: props.market.limitConfiguration?.type,
        },
      };
      if (isSimpleTiered.value) {
        payload.limitConfiguration.tier = newVal;
      } else if (isMainLine.value) {
        payload.limitConfiguration.mainTier = newVal;
        payload.limitConfiguration.altTier = props.market.limitConfiguration?.altTier;
      } else {
        payload.limitConfiguration.altTier = newVal;
        payload.limitConfiguration.mainTier = props.market.limitConfiguration?.mainTier;
      }
      store.dispatch('updateMarketTier', {
        eventId: props.eventId,
        marketId: props.market.marketId,
        payload,
      });
      marketTierInputDisabled.value = true;
      const marketTierDisabledTimeout = setTimeout(() => {
        if (marketTierInputDisabled.value) marketTierInputDisabled.value = false;
        clearTimeout(marketTierDisabledTimeout);
      }, 7000);
    }, 2000);

    const onMarketTierChange = (val) => {
      localMarketTierValue.value = val;
      updateMarketTier(val);
    };

    const onMarketTierIncrease = () => {
      const newValue = localMarketTierValue.value === MARKET_TIER_MAX_VALUE ? MARKET_TIER_MIN_VALUE : localMarketTierValue.value + 1;
      localMarketTierValue.value = newValue;
      updateMarketTier(newValue);
    };
    const onMarketTierDecrease = () => {
      const newValue = localMarketTierValue.value === MARKET_TIER_MIN_VALUE ? MARKET_TIER_MAX_VALUE : localMarketTierValue.value - 1;
      localMarketTierValue.value = newValue;
      updateMarketTier(newValue);
    };

    const marketSelected = computed(() => !!find(props.selectedTradingMarkets, (marketObj) => marketObj.marketId === props.market.marketId));

    const selectMarket = () => {
      store.dispatch('updateSelectedTradingMarkets', {
        marketId: props.market.marketId,
        displayStatus: props.market.displayStatus,
        offeringStatus: props.market.offeringStatus,
      });
    };

    const onBiasUpdate = debounce((val) => {
      const payload = {
        value: val / 100,
        isMainLine: !!props.market.marketSummary?.isMainLine,
      };
      store.dispatch('updateMarketBias', {
        payload,
        marketId: props.market.marketId,
        eventId: props.eventId,
      });
      sliderDisabled.value = true;
      const sliderDisabledTimeout = setTimeout(() => {
        if (sliderDisabled.value) sliderDisabled.value = false;
        clearTimeout(sliderDisabledTimeout);
      }, 7000);
    }, 2000);

    const onBiasSliderUpdate = (val) => {
      biasValueRef.value = val;
      onBiasUpdate(val);
    };

    const openActionsDropdown = () => {
      if (marketHasNoPrice.value || marketSuspendLoading.value) return;
      marketActionsDropdownDisplayed.value = !marketActionsDropdownDisplayed.value;
    };

    const dropdownStyle = computed(() => {
      const dropdownElement = marketActionsDropdownRef.value.getBoundingClientRect();
      const spaceBelow = window.innerHeight - (dropdownElement.bottom + 48);

      if (spaceBelow < 50) {
        return 'top:auto;bottom:54px';
      }
      return 'top:36px;bottom:auto';
    });

    const isFavorite = computed(() => find(props.marketFavorites, (favorite) => favorite.code === props.market.marketCode));

    onClickOutside(marketActionsDropdownRef, () => { marketActionsDropdownDisplayed.value = false; });

    const debouncedBiasRefUpdate = debounce((val) => {
      biasValueRef.value = val;
      sliderDisabled.value = false;
    }, 2000);

    watch(() => biasValue.value, (newValue) => {
      sliderDisabled.value = true;
      if (isNil(biasValueRef.value)) {
        biasValueRef.value = newValue || 0;
        sliderDisabled.value = false;
      } else {
        debouncedBiasRefUpdate(newValue || 0);
      }
    });

    const debouncedMarketTierUpdate = debounce((val) => {
      localMarketTierValue.value = val;
      marketTierInputDisabled.value = false;
    }, 2000);

    watch(() => marketTierValue.value, (newValue) => {
      marketTierInputDisabled.value = true;
      // For initial value set
      if (isNil(localMarketTierValue.value)) {
        localMarketTierValue.value = newValue || 1;
        marketTierInputDisabled.value = false;
      } else {
        debouncedMarketTierUpdate(newValue);
      }
    });

    const showAllLines = () => {
      marketActionsDropdownDisplayed.value = false;
      api.showAllMarketLines({
        eventId: props.eventId,
        marketId: props.market.marketId,
      });
    };
    const hideAllLines = () => {
      marketActionsDropdownDisplayed.value = false;
      api.hideAllMarketLines({
        eventId: props.eventId,
        marketId: props.market.marketId,
      });
    };

    const sliderEnabled = computed(() => (isNil(props.market.marketType?.params?.LINE)
      || (!isNil(props.market.marketType?.params.LINE) && props.market.marketSummary?.isMainLine)));

    const updateMarketFavorites = () => {
      store.dispatch('updateMarketFavorites', { marketCode: props.market?.marketCode, sportId: props.sportId });
    };

    return {
      inputValue,
      marketToggled,
      localMarketTierValue,
      marketTierValue,
      isUSASliderRotate,
      biasValueRef,
      biasValueLabel,
      toggleMarket,
      changeMarketSuspension,
      changeMarketDisplay,
      biasSliderActive,
      isResulted,
      onMarketTierChange,
      onMarketTierIncrease,
      onMarketTierDecrease,
      marketTierInputDisabled,
      marketSelected,
      selectMarket,
      onBiasSliderUpdate,
      marketMargin,
      marketHasNoPrice,
      isHomeAwaySelection,
      sliderDisabled,
      openActionsDropdown,
      marketActionsDropdownDisplayed,
      marketActionsDropdownRef,
      marketSuspendLoading,
      changeMarketSuspensionAllLines,
      dropdownStyle,
      showAllLines,
      hideAllLines,
      marketSelections,
      autoSuspended,
      sliderEnabled,
      isFavorite,
      updateMarketFavorites,
    };
  },
};
</script>

<style lang="scss">
.trading-market {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #DDD;
  background-color: #FFF;
  flex: 100%;
  min-width: 0;

  &.has-borders {
    border-bottom: 0;
    border: 1px solid #DDD;
    border-radius: 4px;
  }

  &.suspended {
    background-color: #FFEEEE;
    border-bottom: 1px solid #FFD5D5;

    .trading-market-header {
      border-bottom: 1px solid #FFD5D5;

      .trading-market-header-buttons-group {
        .trading-market-header-button {
          &.suspend {
            .icon {
              svg {
                path {
                  stroke: #191414;
                }
              }
            }
          }
        }
        &.disabled {
          .trading-market-header-button {
            opacity: 0.5;
            cursor: default;
            &.suspend {
              .icon {
                svg {
                  path {
                    stroke: #191414;
                  }
                }
              }
            }
          }
        }
      }
    }

    .trading-market-risk-hold {
      border-bottom: 1px solid #FFD5D5;
    }
  }

  &.resulted {
    background-color: $gray300;
     border-bottom: 1px solid $gray500;
    .trading-market-header {
      border-bottom: 1px solid $gray500;

    }
    .trading-market-risk-hold {
      border-bottom: 1px solid $gray500;
    }
  }

  &.multi-view {
    border-bottom: 0;
    .trading-market-header {
      height: 24px;
      border-bottom: 0;
      padding: 8px 8px 0;
    }

    .trading-market-risk-hold {
      height: 62px;
      margin-bottom: 0;
    }

    .trading-market-selections-wrapper {
      padding: 12px 16px;

      .trading-market-selection {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .market-not-found {
      height: 50px;
    }
  }

  .trading-market-header {
    height: 66px;
    width: 100%;
    border-bottom: 1px solid #DDD;
    display: flex;
    align-items: center;
    padding: 8px;

    .trading-market-header-icons {
      height: 100%;
      display: flex;
      gap: 4px;
      cursor: pointer;

      &.left {
        margin-right: 4px;
      }

      .icon {
        height: 16px;
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 16px;
          width: 16px;
          path {
            stroke: #191414;
            stroke-width: 1;
          }
        }

        &.checkbox-icon {
          height: 12px;
          width: 12px;

          svg {
            path {
              stroke: #fff;
            }
          }
        }

        &.star-icon {

          svg {
            path {
              stroke-width: 0;
            }
          }
        }
      }
    }

    .trading-market-header-label {
      font-weight: 600;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      height: 100%;
      flex-grow: 1;

      .market-name {
        line-height: 16px;
        white-space: initial;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        max-height: 32px;
        align-self: stretch;
        display: flex;
      }

      .market-statuses {
        font-size: 12px;
        display: flex;
        gap: 4px;
        font-weight: 400;

        .active {
          color: #00BC57;
        }

        .inactive {
          color: #FF2E2D;
        }

        .resulted {
          color: #6D6D6D;
        }

        .dot-separator {
          color: #6D6D6D;
        }
      }
    }

    .trading-market-header-buttons-group {
      display: flex;
      height: 100%;
      width: 64px;
      min-width: 64px;
      position: relative;

      .trading-market-header-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #DDD;
        stroke: #191414;
        background-color: #FFF;
        cursor: pointer;

        &:first-child {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:nth-child(2) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &.suspend {
          svg {
            path {
              stroke: #FF2E2D;
              stroke-width: 2;
            }
          }
        }

        .icon {
          height: 16px;
          width: 16px;

          svg {
            path {
              stroke-width: 1;
              fill: none;
            }
          }

          &.pause {
            svg {
              path {
                stroke-width: 1;
                fill: #FF2E2D;
              }
            }
          }
        }
      }
      &.disabled {
        .trading-market-header-button {
          opacity: 0.5;
          cursor: default;
        }
      }

      .market-actions-dropdown {
        width: 190px;
        min-height: 32px;
        position: absolute;
        right: 0;
        top: 36px;
        border-radius: 4px;
        border: 1px solid #F0F0F0;
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
        z-index: 2;

        .market-actions-dropdown-item {
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 8px;
          border-bottom: 1px solid #F0F0F0;
          cursor: pointer;

          &:hover {
            background-color: rgba(0, 0, 0, 0.02);
          }

          .suspend-lines-label {
            color: #FF2E2D;
          }

          .icon {
            height: 16px;
            width: 16px;
            margin-right: 4px;

            svg {
              height: 16px;
              width: 16px;
              path {
                stroke: #191414;
                stroke-width: 0;
              }
            }

            &.activate-lines {
              svg {
                path {
                  fill: none;
                  stroke-width: 1;
                }
              }
            }

            &.suspend-lines {
              svg {
                path {
                  stroke-width: 1;
                  stroke: #FF2E2D;
                  fill: #FF2E2D;
                }
              }
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }

  .trading-market-risk-hold {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #DDD;
    padding: 0 16px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;

    .trading-market-risk-hold-group {
      display: flex;
      height: 100%;
      align-items: center;

      .trading-market-risk-hold-label {
        margin-right: 8px;
        color: #A9A9A9;
        font-weight: 600;
        font-size: 10px;
      }

      .trading-market-risk-hold-value {
        color: #000;
        font-weight: 400;
        font-size: 14px;
      }

      .market-tier-input {
        width: 58px;
        height: 33px;
      }

    }
  }

  .trading-market-selections-wrapper {
    display: flex;
    width: 100%;
    padding: 0 16px 12px;
    overflow: hidden;

    .trading-market-selections {
      display: flex;
      flex-direction: column;
      width: 100%;
      &.bias-slider-active {
        width: calc(100% - 25px);
      }
    }

    .trading-market-slider {
      display: flex;
      width: 25px;
      justify-content: center;
      align-items: center;
      position: relative;
      .slider-value {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        z-index: 2;
        pointer-events: none;
        &.bias6 {
          top: 14.3%;
        }
        &.bias4 {
          top: 27.6%;
        }
        &.bias2 {
          top: 38%;
        }
        &.bias-2 {
          top: 61%;
        }
        &.bias-4 {
          top: 73%;
        }
        &.bias-6 {
          top: 83.7%;
        }
      }
      .slider {
        .slider__container {
          display: flex;
          .slider__input {
            position: relative;
            left: -22px;
            width: 70px;
            transform: rotate(-90deg);
            background-color: $black;
            color: $white;
            z-index: 1;
            &::-webkit-slider-thumb {
              position: relative;
              z-index: 3;
              background: $black;
              width: 20px;
              height: 20px;
            }

            &::-moz-range-thumb {
              position: relative;
              z-index: 3;
              background: $black;
              width: 20px;
              height: 20px;
            }

            &.disabled {
              background-color: $gray700;

              &::-webkit-slider-thumb {
                background-color: $gray700;
                border-color: $gray700;
                cursor: not-allowed;
              }

              &::-moz-range-thumb {
                background-color: $gray700;
                border-color: $gray700;
                cursor: not-allowed;
              }
            }
          }
        }

        &.is-positive-rotate {
          .slider__container {
            .slider__input {
              transform: rotate(90deg);
            }
          }
        }

      }
    }
  }

  .market-not-found {
    height: 147px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #A9A9A9;
  }
}
</style>
