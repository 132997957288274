<template>
  <div class="player-props-root">
    <div
      v-if="!isLoading && data.length"
      class="single-player-props-container"
    >
      <div
        v-for="player in data"
        :key="player.playerIndex"
        class="single-player-props"
      >
        <div class="player-props-header">
          <p class="player-name">
            <span>{{ formatPlayerName(player.playerName) }}</span>
            <Icon
              v-if="player.star"
              name="star"
            />
          </p>
          <p
            v-if="player.teamName"
            class="team-name"
          >
            <span>{{ player.teamName }}</span>
          </p>
        </div>
        <div class="player-params-container">
          <div
            v-for="param in playerParamsBySport"
            :key="param.param"
            class="player-params-single"
          >
            <div class="params-single-header">
              {{ param.label }}
            </div>
            <NumberInput
              class="player-param-input"
              :class="{ 'is-error': param.isError }"
              :model-value="player[param.param]"
              :is-error="checkIsError(player[param.param], param.param)"
              :error-message="getErrorMessage(param.param)"
              :has-arrows="true"
              @update:modelValue="onPlayerPropChange($event, player, param)"
              @onKeyUp="onPlayerPropIncrease(player, param)"
              @onKeyDown="onPlayerPropDecrease(player, param)"
            />
          </div>
        </div>
        <MarketsPerPlayerSingle
          class="markets-per-player-container"
          :player-market-info="player"
          :team-label="player.teamCode"
          :sport-id="sportId"
          :lineup="totalLineup"
        />
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <div
      v-if="!isLoading && !data.length"
      class="no-data-message"
    >
      No data for current selection
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { split } from 'lodash';
import {
  footballParameters, basketballParameters, hockeyParameters,
} from '@/services/helpers/trading-ui-mapper';
import sportIds from '@/services/helpers/sports';
import NumberInput from '@/components/common/NumberInput';
import Spinner from '@/components/common/Spinner';
import Icon from '@/components/common/Icon';
import MarketsPerPlayerSingle from '@/components/trading-ui/multiview/event-drawer/params/MarketsPerPlayerSingle';
import { getLowerLimit, getUpperLimit } from '@/services/helpers/player-setup-mapper';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  HOCKEY_ID,
} = sportIds;

export default {
  emits: ['onPropChange', 'onPropIncrease', 'onPropDecrease'],
  components: {
    NumberInput,
    Spinner,
    Icon,
    MarketsPerPlayerSingle,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const sportId = computed(() => route.query.sport || store.getters.tradingEvent?.sportId);
    const isLoading = computed(() => store.getters.tradingPlayerPropsLoading);
    const totalLineup = computed(() => [
      ...(props.event?.homeLineup || []),
      ...(props.event?.awayLineup || []),
    ]);
    const playerParamsBySport = computed(() => {
      switch (sportId.value) {
      case BASKETBALL_ID:
        return basketballParameters();
      case FOOTBALL_ID:
        return footballParameters();
      case HOCKEY_ID:
        return hockeyParameters();
      default:
        return {};
      }
    });

    const formatPlayerName = (name) => {
      const formatedName = split(name, ',');
      return `${formatedName[1]} ${formatedName[0]}`;
    };

    const checkIsError = (newVal, param) => newVal < getLowerLimit([param]) || newVal > getUpperLimit([param]);
    const getErrorMessage = (param) => `Value must be between ${getLowerLimit([param])} and ${getUpperLimit([param])}`;

    const onPlayerPropChange = (newVal, player, param) => {
      emit('onPropChange', {
        playerId: player.playerId,
        teamCode: player.teamCode,
        param: param.param,
        value: newVal,
      });
    };

    const onPlayerPropIncrease = (player, param) => {
      emit('onPropIncrease', {
        playerId: player.playerId,
        teamCode: player.teamCode,
        param: param.param,
      });
    };
    const onPlayerPropDecrease = (player, param) => {
      emit('onPropDecrease', {
        playerId: player.playerId,
        teamCode: player.teamCode,
        param: param.param,
      });
    };

    return {
      playerParamsBySport,
      isLoading,
      sportId,
      formatPlayerName,
      onPlayerPropChange,
      onPlayerPropIncrease,
      onPlayerPropDecrease,
      checkIsError,
      getErrorMessage,
      totalLineup,
    };
  },
};
</script>

<style lang="scss">
.player-props-root {
    height: 100%;
    padding: 0 16px 16px 16px;
    .single-player-props-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .single-player-props {
        .player-props-header {
           .player-name {
              display: flex;
              align-items: center;
              gap: 8px;
              color: $black;
              font-size: 14px;
              font-weight: 600;

              .icon {
                width: 14px;
                height: 14px;
              }
           }
           .team-name {
            color: $gray800;
            font-size: 12px;
           }
        }
        .player-params-container {
            padding: 16px 0;
            display: flex;
            gap: 16px;
            flex-wrap: wrap;

            .player-params-single {
                width: 67px;
                .params-single-header {
                  font-size: 10px;
                  font-weight: 600;
                  text-transform: uppercase;
                  color: $gray700;
                  margin-bottom: 4px;
                }
                .player-param-input {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .markets-per-player-container {
          .player-actions-btn {
            justify-content: space-between;
          }
        }
      }
    }
    .no-data-message {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
}

</style>
